<template flex>
  <div class="login d-flex justify-center">
    <v-main class="d-flex">
      <v-layout fluid flex flex-wrap justify-center class="d-flex">
        <v-flex d-flex xs12 sm5 align-center justify-center elevation-0 class="white">
          <v-card flat class="v-login-box rounded-0" width="100%">
            <div class="d-flex mb-16 pb-3 back-to-screen">
              <a href="/login" class="text-decoration-none black--text"
                ><v-icon medium left class="black--text"> arrow_back </v-icon> Back To Login</a
              >
            </div>
            <v-layout class="flex-column v-login-info">
              <v-img max-width="200" src="../assets/images/logo.png"></v-img>
              <p class="mb-0">Welcome to SearchAds Maven, the conquesting expert for app marketers who want to win every search and achieve top results on the Apple App Store.</p>
            </v-layout>
            <v-card-text class="py-4 px-0">
              <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="signupOry">
                <label class="black--text mb-2 d-flex">Email Address</label>
                <v-text-field
                  placeholder="Enter your e-mail address"
                  v-model="email"
                  required
                  outlined
                  class="rounded-0"
                  dense
                  :rules="emailRules"
                  :disabled="emailEnable"
                ></v-text-field>

                <label class="black--text mb-2 d-flex">Password</label>
                <v-text-field
                  v-model="password"
                  :append-icon="toggle ? 'visibility' : 'visibility_off'"
                  :type="toggle ? 'text' : 'password'"
                  @click:append="toggle = !toggle"
                  min="6"
                  required
                  outlined
                  class="rounded-0"
                  dense
                  :rules="nameRules"
                ></v-text-field>

                <v-layout justify-end flex-wrap>
                  <v-btn
                    block
                    depressed
                    large
                    :disabled="enableLogin||!valid"
                    class="primary white--text rounded-0"
                    @click="signupOry()"
                    >Sign Up</v-btn
                  >
                </v-layout>

                <v-layout flex-wrap class="py-4 justify-space-between">
                  <v-row justify="center" flex-wrap>
                    <v-col sm="12" md="12" lg="6">
                      <v-btn
                        block
                        depressed
                        outlined
                        large
                        class="my-2 secondary--text v-social-btn rounded-0"
                      >
                        <v-img
                          max-height="22"
                          max-width="22"
                          src="../assets/images/google.svg"
                        ></v-img>
                        <span>Sign in with Google</span>
                      </v-btn>
                    </v-col>
                    <v-col sm="12" md="12" lg="6">
                      <v-btn
                        block
                        depressed
                        outlined
                        large
                        class="my-2 secondary--text v-social-btn rounded-0"
                      >
                        <v-img
                          max-height="22"
                          max-width="22"
                          src="../assets/images/linkedin.svg"
                        ></v-img>
                        <span>Sign in with Linkedin</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-layout>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex
          xs12
          sm7
          justify-center
          elevation-0
          blue
          accent-4
          class="v-login-right style-2"
        >
          <!-- <v-card flat class="v-login-right-inner">
            <div class="pt-4 white--text">
              <h4 class="font-weight-medium pb-6">
                The Kochava Marketers Operating System™
              </h4>
              <p class="white-text">
                The Marketers Operating System (m/OS) seamlessly integrates
                omni-channel marketing solutions for advertisers and publishers
                in one operational platform.
              </p>
            </div>
            <v-card-text class="pt-4 px-0 white--text">
              <v-img src="../assets/images/login-img.svg"></v-img>
            </v-card-text>
          </v-card> -->
          <h2>Win every search.</h2>
        </v-flex>
      </v-layout>
      <div class="text-center">
          <v-dialog
            v-model="loading"
            hide-overlay
            persistent
            width="300"
          >
            <v-card
            >
              <v-card-title class="headline grey lighten-2">
                Processing...
              </v-card-title>
              <v-card-text
                class="cust"
              >
                <v-progress-linear
                  indeterminate
                  color="primary"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
      </div>
      <div class="text-center">
        <v-dialog
          v-model="msgModel"
          width="300"
        >
          <v-card>
            <v-card-title class="headline grey lighten-2">
              {{ msgTitle }}
            </v-card-title>

            <v-card-text class="success--text">
              {{ message }}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions v-if="login">
              <v-spacer></v-spacer>
              <v-btn
                color="primary success" style="color:#fff"
                text
                @click="goToLogin"
              >
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-main>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// import Button from '../stories/Button.vue'
// import Text from '../stories/Text.vue'
// import Password from '../stories/Password.vue'
import Vue from 'vue';
import { SignUpService } from '@services/signup.service';

export default Vue.extend({
  name: 'SignUp',
  components: {
    // 'my-button': Button,
    // 'my-text': Text,
    // 'my-pass': Password
  },

  data() {
    return {
      id: '',
      emailEnable: false,
      email: null,
      password: null,
      inputEmail: 'Input your E-Mail Id here',
      inputPassword: 'Input your password here',
      passwordType: '',
      toggle:false,
      loading: false,
      valid: false,
      msgModel: false,
      message :'',
      msgTitle: '',
      login: false,
      enableLogin: false,
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      nameRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password must be atleast 6 characters',
      ],
    };
  },
  methods: {
    async signupOry() {
      this.$refs.form.validate();
      if(!this.email || !this.password){
        return;
      }
      if(!this.valid){
        return;
      }
      this.loading = true;
      this.enableLogin = true;
      await SignUpService.signupOry({
        Email: this.email,
        Password: this.password,
      })
      .then((res) => {
        //console.log(res);
        if(res.code == 0){
            if (this.id != '') {
              this.login = true;
              this.loading = false;
              this.msgModel = true;
              this.msgTitle = 'Success!!!';
              this.message = 'You can login now'
            }else{
              this.login = true;
              this.loading = false;
              this.msgModel = true;
              this.msgTitle = 'Success!!!';
              this.message = 'Please check your mail to verify and login'
            }
        }else if(res.code == 1){
            this.loading = false;
            this.msgModel = true;
            this.msgTitle = 'Error!!!'
            this.message = "Email already exists. Please try login"
            this.enableLogin = false;
        }else if(res.code == 7){
            this.loading = false;
            this.msgModel = true;
            this.msgTitle = 'Error!!!'
            this.message = "Email already exists. Please try login"
            this.enableLogin = false;
        }else if(res.code == 5){
            this.loading = false;
            this.msgModel = true;
            this.msgTitle = 'Error!!!'
            this.message = "Server Error!!! Plea"+res.code+"e try later"
            this.enableLogin = false;
        }else if(res.code == 6){
            this.loading = false;
            this.msgModel = true;
            this.msgTitle = 'Error!!!'
            this.message = "Weak password!!! Please try something strong"
            this.enableLogin = false;
        }else {
            this.loading = false;
            this.msgModel = true;
            this.msgTitle = 'Error!!!'
            this.message = "Server Error!!! Please try later"
            this.enableLogin = false;
        }
      })
      .catch((err) => {
            console.log(err);
            this.loading = false;
            this.msgModel = true;
            this.msgTitle = 'Error!!!'
            this.message = "Server Error!!! Please try later"
            this.enableLogin = false;
        })
    },
    goToLogin() {
      this.login = false;
      this.msgModel = false;
      return this.$router.push('/login');
    },
  },
  mounted() {
    this.valid = false;
    const id = this.$route.query.id ? this.$route.query.id : '';
    if (id && /.+@.+\..+/.test(id)) {
      this.id = id;
      this.email = id;
      this.emailEnable = true;
    }  else {
      this.id = '';
      this.email = '';
      this.emailEnable = false;
    }
  }
});
</script>
<style lang="scss">
.cust {
  text-align: center !important;
  padding: 10px 24px 20px !important;
}
</style>
