var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login d-flex justify-center" },
    [
      _c(
        "v-main",
        { staticClass: "d-flex" },
        [
          _c(
            "v-layout",
            {
              staticClass: "d-flex",
              attrs: {
                fluid: "",
                flex: "",
                "flex-wrap": "",
                "justify-center": ""
              }
            },
            [
              _c(
                "v-flex",
                {
                  staticClass: "white",
                  attrs: {
                    "d-flex": "",
                    xs12: "",
                    sm5: "",
                    "align-center": "",
                    "justify-center": "",
                    "elevation-0": ""
                  }
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "v-login-box rounded-0",
                      attrs: { flat: "", width: "100%" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex mb-16 pb-3 back-to-screen" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "text-decoration-none black--text",
                              attrs: { href: "/login" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "black--text",
                                  attrs: { medium: "", left: "" }
                                },
                                [_vm._v(" arrow_back ")]
                              ),
                              _vm._v(" Back To Login")
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "v-layout",
                        { staticClass: "flex-column v-login-info" },
                        [
                          _c("v-img", {
                            attrs: {
                              "max-width": "200",
                              src: require("../assets/images/logo.png")
                            }
                          }),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "Welcome to SearchAds Maven, the conquesting expert for app marketers who want to win every search and achieve top results on the Apple App Store."
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "py-4 px-0" },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: { "lazy-validation": "" },
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.signupOry.apply(null, arguments)
                                }
                              },
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "black--text mb-2 d-flex" },
                                [_vm._v("Email Address")]
                              ),
                              _c("v-text-field", {
                                staticClass: "rounded-0",
                                attrs: {
                                  placeholder: "Enter your e-mail address",
                                  required: "",
                                  outlined: "",
                                  dense: "",
                                  rules: _vm.emailRules,
                                  disabled: _vm.emailEnable
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email"
                                }
                              }),
                              _c(
                                "label",
                                { staticClass: "black--text mb-2 d-flex" },
                                [_vm._v("Password")]
                              ),
                              _c("v-text-field", {
                                staticClass: "rounded-0",
                                attrs: {
                                  "append-icon": _vm.toggle
                                    ? "visibility"
                                    : "visibility_off",
                                  type: _vm.toggle ? "text" : "password",
                                  min: "6",
                                  required: "",
                                  outlined: "",
                                  dense: "",
                                  rules: _vm.nameRules
                                },
                                on: {
                                  "click:append": function($event) {
                                    _vm.toggle = !_vm.toggle
                                  }
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password"
                                }
                              }),
                              _c(
                                "v-layout",
                                {
                                  attrs: { "justify-end": "", "flex-wrap": "" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "primary white--text rounded-0",
                                      attrs: {
                                        block: "",
                                        depressed: "",
                                        large: "",
                                        disabled: _vm.enableLogin || !_vm.valid
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.signupOry()
                                        }
                                      }
                                    },
                                    [_vm._v("Sign Up")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-layout",
                                {
                                  staticClass: "py-4 justify-space-between",
                                  attrs: { "flex-wrap": "" }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        justify: "center",
                                        "flex-wrap": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: { sm: "12", md: "12", lg: "6" }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "my-2 secondary--text v-social-btn rounded-0",
                                              attrs: {
                                                block: "",
                                                depressed: "",
                                                outlined: "",
                                                large: ""
                                              }
                                            },
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  "max-height": "22",
                                                  "max-width": "22",
                                                  src: require("../assets/images/google.svg")
                                                }
                                              }),
                                              _c("span", [
                                                _vm._v("Sign in with Google")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: { sm: "12", md: "12", lg: "6" }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "my-2 secondary--text v-social-btn rounded-0",
                                              attrs: {
                                                block: "",
                                                depressed: "",
                                                outlined: "",
                                                large: ""
                                              }
                                            },
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  "max-height": "22",
                                                  "max-width": "22",
                                                  src: require("../assets/images/linkedin.svg")
                                                }
                                              }),
                                              _c("span", [
                                                _vm._v("Sign in with Linkedin")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  staticClass: "v-login-right style-2",
                  attrs: {
                    xs12: "",
                    sm7: "",
                    "justify-center": "",
                    "elevation-0": "",
                    blue: "",
                    "accent-4": ""
                  }
                },
                [_c("h2", [_vm._v("Win every search.")])]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "v-dialog",
                {
                  attrs: { "hide-overlay": "", persistent: "", width: "300" },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "headline grey lighten-2" },
                        [_vm._v(" Processing... ")]
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "cust" },
                        [
                          _c("v-progress-linear", {
                            staticClass: "mb-0",
                            attrs: { indeterminate: "", color: "primary" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "v-dialog",
                {
                  attrs: { width: "300" },
                  model: {
                    value: _vm.msgModel,
                    callback: function($$v) {
                      _vm.msgModel = $$v
                    },
                    expression: "msgModel"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "headline grey lighten-2" },
                        [_vm._v(" " + _vm._s(_vm.msgTitle) + " ")]
                      ),
                      _c("v-card-text", { staticClass: "success--text" }, [
                        _vm._v(" " + _vm._s(_vm.message) + " ")
                      ]),
                      _c("v-divider"),
                      _vm.login
                        ? _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { color: "#fff" },
                                  attrs: { color: "primary success", text: "" },
                                  on: { click: _vm.goToLogin }
                                },
                                [_vm._v(" Ok ")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }